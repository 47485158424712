import React from "react";
import './Navbar.css';
import Toggle from "../Toggle/Toggle";

import { Link } from 'react-scroll';

const Navbar = () => {
    return(
        <div className="n-wrapper">
            <div className="n-left">
                <div className="n-name">Ritik</div>
                <Toggle/>
            </div>
            <div className="n-right">
                <div className="n-list">
                <ul style={{listStyleType: 'none'}}>
                    <Link spy={true} to ='Navbar' smooth={true} activeClass="activeClass">
                    <li>Home</li>
                    </Link>   
                    <Link spy={true} to ='Services' smooth={true}>
                    <li>Services</li>
                    </Link>           
                    <Link spy = {true} to ='Portfolio' smooth={true}>
                    <li>ClientWork</li> {/* Portfolio change the name */}
                    </Link>
                    <Link spy={true} to='Projects' smooth={true}>
                    <li>Projects</li>
                    </Link>
                    <Link spy={true} to ='Testimonial' smooth={true}>
                    <li>Testimonial</li>
                    </Link>
                </ul>
                  </div>
                  <Link spy ={true} to ='Contact' smooth={true}>
                        <div className="button n-button">
                            Contact
                        </div>
                  </Link>
            </div>
        </div>
    );

};

export default Navbar