import React from "react";
import  './Footer.css'

import Insta from '@iconscout/react-unicons/icons/uil-instagram'
import Facebook from '@iconscout/react-unicons/icons/uil-facebook'
import Github from '@iconscout/react-unicons/icons/uil-github'
import Linkedin from '@iconscout/react-unicons/icons/uil-linkedin'

import Wave from '../../img/wave.png'

const Footer = () => {
    return(
        <div className="footer">
            <img src={Wave} alt=""  style={{width: '100%'}}/>
            <div className="f-content">
                <div className="e-mail">
                    <span>ritikbillore20@gmail.com</span>
                </div>
                <div className="f-icons">
                <a href='https://github.com/Ritikbillore18'>
                        <Github color="white" size='3rem' />
                </a>
                <a href='https://www.linkedin.com/in/ritik-billore-7a6669234'>
                        <Linkedin color="white" size='3rem' />
                </a>
                <a href='https://www.facebook.com/share/DjAkmKrUZw16pGSN/?mibextid=qi2Omg'>
                        <Facebook color="white" size='3rem' />
                </a>
                <a href='https://www.instagram.com/ritikbillore?igsh=bTQxODM0NmFsZHE1'>
                         <Insta color="white" size='3rem' />     
                </a>
                </div>
            </div>
        </div>
    );
};

export default Footer