import React from "react";
import './Works.css'
import Upwork from "../../img/Upwork.png";
import Fiverr from "../../img/fiverr.png";
import Amazon from "../../img/amazon.png";
import Shopify from "../../img/Shopify.png";
import Facebook from "../../img/Facebook.png";

import { themeContext } from '../../Context';
import { useContext } from "react";

import { motion} from 'framer-motion';
import { Link } from 'react-scroll';

const Works = () => {

    const  theme = useContext(themeContext);
    const  darkMode = theme.state.darkMode;
    return(
        <div className="works">
            {/* Left Side */ }
          <div className="awesome">
            <span style = {{color: darkMode? 'white': ''}}>Work for The</span>
            <span>Clinet Project</span>
            <span> 
                Hi ! I am Ritik Billore a passionate full-stack developer with a knack for creating  dynamic web applications.    
                <br/>
                A Vite.js-powered online store delivering a seamless,
                <br/>
                flavorful shopping experience with an extensive spice catalog,
                <br/>
                secure checkout, and curated recommendations.
            </span>
            <Link spy ={true} to ='Contact' smooth={true}>
            <button className="button s-button">
                Hire Me
                </button>
                  </Link>
            <div className="blur s-blur1 " style={{background: "#ABF1FF94"}}></div>
           </div>  

           {/* Right Side */ }
        <div className="w-right">
            <motion.div
                initial={{ rotate: 45}}
                whileInView={{ rotate: 0 }}
                viewport={{margin: '-40px'}}
                transition={{duration: 3.5 , type: 'spring'}}
                className="w-mainCircle">
                <div className="w-secCircle">
                    <img src={Upwork} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Fiverr} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Amazon} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Shopify} alt="" />
                </div>
                <div className="w-secCircle">
                    <img src={Facebook} alt="" />
                </div>
            </motion.div>
                {/* background Circles */}
                <div className="w-backCircle blueCircle"></div>
                <div className="w-backCircle yellowCircle"></div>
           </div>
        </div>
    );
};

export default Works