import React from 'react'
import './Services.css'
import HeartEmoji from '../../img/heartemoji.png';
import Glasses from '../../img/glasses.png';
import Humble from '../../img/humble.png';
import Card from '../Card/Card';
import Resume from './RitikResume.pdf';

import { themeContext } from '../../Context';
import { useContext } from "react";

import { motion } from 'framer-motion';


const Services = () => {

    const transition = {duration : 2, type: 'spring'}

    const  theme = useContext(themeContext);
    const  darkMode = theme.state.darkMode;

    return(
        <div className="services" id='Services'>

        { /* left side */ }
        <div className="awesome">
            <span style = {{color: darkMode? 'white': ''}}>My Awesome</span>
            <span>Services</span>
            <span> 
            Hello, I'm Ritik, a passionate full-stack developer with a knack for 
            <br/>
            creating innovative and dynamic web applications. <br/> 
            I specialize in both front-end and back-end development, 
            <br/>
            bringing ideas to life with a comprehensive skill set. Some of my standout projects       
            </span>
            <a href={Resume}  download>
            <button className="button s-button">Download CV</button>
            </a> 
            <div className="blur s-blur1 " style={{background: "#ABF1FF94"}}></div>
        </div>  

        {/* right side */}
        <div className="cards">
            <motion.div 
                whileInView={{left: '18rem'}}
                initial ={{left: '25rem'}}
                transition={transition}

            style={{left: '20rem'}}>
                <Card 
                emoji = {HeartEmoji}
                heading = {'SpicesHeaven'}
                detail = {"A Vite.js store providing a seamless shopping experience for spices"}
                />
            </motion.div>
                     {/*  second card */}
            <motion.div 
                whileInView={{left: '2rem'}}
                initial ={{left: '-6rem'}}
                transition={transition}
            style={{top: "12rem", left: "2rem" }} >
                <Card
                    emoji={Glasses}
                    heading={"Royal Enfield Bike Showroom"}
                    detail={"  A robust Java app with bike listings, EMI calculators, and test ride bookings."}
                    />
            </motion.div>
                         {/*  Third  card */}
            <motion.div
                whileInView={{left: '25rem'}}
                initial ={{left: '40rem'}}
                transition={transition}
            style={{top: "19rem", left: "32rem" }} >
                <Card
                    emoji={Humble}
                    heading={"Personal Portfolio"}
                    detail={"Developed with React to showcase my skills, projects, and professional journey"}
                    />
            </motion.div>
        <div className="blur s-blur2" style={{ background: "var(--purple)"}}></div>
        
        </div>

        </div>      
    )
}

export default Services