import React from "react";
import './Projects.css';
const Projects = () => {
    return(
        <div className="project" id='Projects'>
        <div className="achievement">
            <a href="https://www.linkedin.com/posts/ritik-billore-7a6669234_javadevelopment-webdevelopment-royalenfield-activity-7215740320280395776-l0Sj?utm_source=share&utm_medium=member_desktop" >
            <div className="circle"> 3+ </div> </a>
                <span>Completed</span>
                <span>Projects</span>
        </div>

        <div className="achievement">
        <a href="https://www.linkedin.com/posts/ritik-billore-7a6669234_onlinespicestorage-spices-webdevelopment-activity-7209969741317263361-lmJD?utm_source=share&utm_medium=member_desktop" >
            <div className="circle"> 1+ </div> </a>
                <span>Client</span>
                <span>Projects</span>
        </div>

        </div>
    )
}

export default Projects