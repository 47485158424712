import React from "react";
import './Testimonial.css';
import {Swiper , SwiperSlide } from "swiper/react";
import profilePic1 from "../../img/profile1.jpeg";
// import profilePic2 from "../../img/profile2.jpg";
import "swiper/css";
 /*  import { Pagination } from "swiper/modules";
import 'swiper/css/pagination';
import 'swiper/css'  */
    
const Testimonial = () => {
    const clients = [
        {
            img: profilePic1,
            review:
            "Working with Ritik Billore on SpicesHeaven was fantastic. They exceeded our expectations, delivering a seamless, intuitive, and secure online store. Highly recommend! - Virendr Nagraj"
        },
       /* {
            img: profilePic2,
            review:
            "hey this the project of the cycle selling ",
        }, */
    ];
    return (
        <div className="t-wrapper" id='Testimonial'>
            <div className="t-heading">
                <span>Clients always get</span>
                <span>Exceptional Work</span>
                <span>From me. . .</span>
                <div className="blur t-blur1" style={{ background: "var(--purple)"}}></div>
                <div className="blur t-blur2" style={{ background: "skyblue"}}></div>
            </div>
            {/* slider */}
            <Swiper
             /*  modules={[Pagination]}
               slidesPerView={1}
               pagination={{clickable: true}}  */
            >
                 {clients.map((client, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <div className="testimonial">
                                <img src={client.img} alt="" />
                                <span>{client.review}</span>
                            </div>
                        </SwiperSlide>
                    )
                 })}
            </Swiper>

        </div>
    );
};

export default Testimonial;