import React from "react";
import './Portfolio.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import Portfolioimg from "../../img/Portfolio.png";
import Showroom from "../../img/Showroom-project - Made with Clipchamp.mp4";
import Spiceshevan from "../../img/SpicesHeaven vedio.mp4";
import ReactPlayer from 'react-player';
import "swiper/css";

import { themeContext } from '../../Context';
import { useContext } from "react";

const Portfolio = () => {
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="portfolio" id="Portfolio">
            {/* Heading */}
            <span style={{ color: darkMode ? 'white' : '' }}>Recent Works</span>
            <span>Projects</span>

            {/* Slider */}
            <Swiper
                spaceBetween={30}
                slidesPerView={3}
                grabCursor={true}
                className='portfolio-slide'
            >
                <SwiperSlide>
                    <img src={Portfolioimg} alt="" />
                </SwiperSlide>
                <SwiperSlide>
                    <div className="react-player-wrapper">
                        <ReactPlayer
                            className='react-player'
                            controls={true}
                            url={Spiceshevan}
                            width="100%"
                            height="100%"
                        />
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="react-player-wrapper">
                        <ReactPlayer
                            className='react-player'
                            controls={true}
                            url={Showroom}
                            width="100%"
                            height="100%"
                        />
                    </div>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Portfolio;
