import React from 'react'
import './Intro.css'
import FloatingDiv from '../FloatingDiv/FloatingDiv';
import Github from '../../img/github.png';
import LinkedIn from '../../img/linkedin.png';
import Instagram from '../../img/instagram.png';
import Vector1 from '../../img/Vector1.png';
import Vector2 from '../../img/Vector2.png';
import boy from '../../img/boy.png';
import thumbup from '../../img/thumbup.png';
import Crown from '../../img/crown.png';
import glassesimoji from '../../img/glassesimoji.png';

import { themeContext } from '../../Context';
import { useContext } from "react";
import { Link } from 'react-scroll';
import { motion} from 'framer-motion';


const Intro = () => {

        
        const transition = {duration : 2, type: 'spring'}

        const  theme = useContext(themeContext);
        const  darkMode = theme.state.darkMode;

    return(
        <div className="intro">
            <div className="i-left">
                <div className="i-name">
                    <span style = {{color: darkMode? 'white': ''}}>Hy ! I am </span>
                    <span>Ritik Billore</span>
                    <span>I am a Full-stack developer with a strong focus on Backend development, specializing in creating robust and efficient server-side applications. My expertise ensures seamless integration and optimal performance for complex digital solutions.</span>
                </div>
                <Link spy ={true} to ='Contact' smooth={true}>
                <button className="button i-button">
                    Hire me
                    </button>
                  </Link>
                <div className="i-icons">
                    <a href='https://github.com/Ritikbillore18'>
                    <img src={Github} alt="" />
                    </a>
                    <a href='https://www.linkedin.com/in/ritik-billore-7a6669234'>
                    <img src={LinkedIn} alt="" />
                    </a>
                    <a href='https://www.instagram.com/ritikbillore?igsh=bTQxODM0NmFsZHE1'>
                    <img src={Instagram} alt="" />
                    </a>

                </div>


            </div>
            <div className="i-right">

            <img src={Vector1} alt="" />
            <img src={Vector2} alt="" />
            <img src={boy} alt="" /> 
            <motion.img
                initial={{ left:  '-36%' }}
                whileInView={{ left : '-24%'}}
                transition={transition}
            src={glassesimoji} alt="" />  
            <motion.div
                initial = {{ top: '-4%' , left: '74%'}}
                whileInView={{left: '68%'}}
                transition={transition}
            
            style={{top: '-4%', left: '68%'}} className='floating-div'>
                <FloatingDiv image={Crown} txt1='Software' txt2='Engineer' />
            </motion.div>   
            <motion.div 
                 initial = {{ top: '18rem' , left: '9rem'}}
                 whileInView={{left: '0rem'}}
                 transition={transition}    
            
            style={{top: '18rem' , left: '0rem'}} className='floating-div'>
                <FloatingDiv image={thumbup} txt1 = 'Fullstack' txt2 = 'Devloper'/>
            </motion.div>   
            {/* blur divs */}   
            <div className="blur" style={{background: "rgb(238 210 255)" }}></div>
            <div className="blur" style={{
                background: '#C1F5FF',
                top: '17rem',
                width: '21rem',
                height: '11rem',
                left: '-9rem'
            }}></div>
            </div>
        </div>
    )
}

export default Intro